import React from 'react';
import Carousel_image_1 from '../images/1.jpg';
import Carousel_image_2 from '../images/2.jpg';
import Carousel_image_3 from '../images/3.jpg';


const Carousel = () => {
    return (
        <div className="carousel slide" id="carouselExampleIndicators">
            <div className="carousel-indicators">
                <button aria-current="true" aria-label="Slide 1" className="active" data-bs-slide-to="0"
                        data-bs-target="#carouselExampleIndicators" type="button"></button>
                <button aria-label="Slide 2" data-bs-slide-to="1" data-bs-target="#carouselExampleIndicators"
                        type="button"></button>
                <button aria-label="Slide 3" data-bs-slide-to="2" data-bs-target="#carouselExampleIndicators"
                        type="button"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img alt="..." className="d-block w-100" src={Carousel_image_1}/>
                </div>
                <div className="carousel-item">
                    <img alt="..." className="d-block w-100" src={Carousel_image_2}/>
                </div>
                <div className="carousel-item">
                    <img alt="..." className="d-block w-100" src={Carousel_image_3}/>
                </div>
            </div>
            <button className="carousel-control-prev" data-bs-slide="prev" data-bs-target="#carouselExampleIndicators"
                    type="button">
                <span aria-hidden="true" className="carousel-control-prev-icon"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" data-bs-slide="next" data-bs-target="#carouselExampleIndicators"
                    type="button">
                <span aria-hidden="true" className="carousel-control-next-icon"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default Carousel;