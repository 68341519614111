import React, {useEffect, useState} from 'react';

const Section = () => {
    const [napisImage, setNapisImage] = useState('');
    const [ornamentImage, setOrnamentImage] = useState('');
    const [ornament1Image, setOrnament1Image] = useState('');
    const [ornament2Image, setOrnament2Image] = useState('');

    useEffect(() => {
        import('../images/svatby-na-provazku---napis.svg')
            .then(image => setNapisImage(image.default))
            .catch(err => console.error('Failed to load image', err));

        import('../images/ornament.svg')
            .then(image => setOrnamentImage(image.default))
            .catch(err => console.error('Failed to load image', err));

        import('../images/ornament-1.svg')
            .then(image => setOrnament1Image(image.default))
            .catch(err => console.error('Failed to load image', err));

        import('../images/ornament-2.svg')
            .then(image => setOrnament2Image(image.default))
            .catch(err => console.error('Failed to load image', err));
    }, []);
    return (
        <section>
            <div className="container" style={{marginTop: "0vw"}}>
                <p>&nbsp;</p>
                <br/>
                <div className="container" style={{marginTop: "0vw"}}>
                    <div className="row">
                        <div className="col-12 text-center">
                            <img alt="Responsive" className="img-fluid"
                                 src={napisImage}
                                 style={{height: "12vh", paddingLeft: "1vw"}}/>
                            <br/>
                            <img alt="Responsive" className="img-fluid" src={ornamentImage}
                                 style={{height: "7vh", paddingLeft: "1vw"}}/>
                        </div>
                    </div>
                </div>
                <div className="row" style={{maxWidth: "80%", textAlign: "center", margin: "0 auto"}}>
                    <div className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <small style={{fontSize: "1rem"}}>Příprava svatby Vás stála spousty času a úsilí. Snažila jste
                            se, aby všechno bylo dokonalé a
                            všichni
                            hosté byli spokojeni. Zorganizovat svatbu tak, aby byla přesně podle Vašich představ Vás
                            stálo
                            spoustu
                            úsilí, času a mnohdy i stresu. Pokud si chcete svůj Den užít, přeneste veškeré starosti na
                            mě,
                            na
                            svatební koordinátorku. Smyslem svatební koordinátorky je ulehčit přípravy, podělit se o
                            zkušenosti a
                            zajistit, abyste se ve svatební den cítili opravdu dobře a neměli žádné starosti.
                            <br/>
                            <br/>
                            Koordinátorka vidí a zná věci, které vaši přátelé bez praxe znát nemohou, je vždy o krok či
                            dva
                            napřed a
                            dokáže ušetřit nejen peníze a čas, ale jejím hlavním zájmem je zajistit tolik ceněnou
                            psychickou
                            pohodu
                            všech svatebčanů a hladký průběh celé svatby. Pokud spolupráci zvažujete, informujte se u
                            přátel
                            a
                            jiných nevěst. Vždyť nejlepším doporučením je vždy osobní zkušenost. Dopřejte si pocit
                            výjimečnosti
                            alespoň jednou za život. Společně zvládneme přípravy na svatbu i svatební den v klidu, s
                            radostí
                            a
                            úsměvem na tváři.</small>
                    </div>
                </div>
                <div className="container" style={{marginTop: "3vw"}}>
                    <div className="row">
                        <div className="col-12 text-center">
                            <img alt="Responsive" className="img-fluid"
                                 src={ornament1Image}
                                 style={{height: "20vh", paddingLeft: "1vw"}}/>
                        </div>
                    </div>
                </div>
                <p>&nbsp;</p>
                <hr/>
                <div className="row" style={{maxWidth: "80%", textAlign: "center", margin: "0 auto"}}>
                    <div className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <h2>1. SVATEBNÍ PORADENSTVÍ<br/><br/></h2>
                        <small style={{fontSize: "1rem"}}>Poradím Vám, jak začít s plánováním svatby, co kdy je třeba
                            zajistit, jaké úřední náležitosti je
                            nutné
                            vyřídit a nač si dát při plánování svatby pozor.
                            <br/>
                            Do detailu rozebereme Vaše představy. Připomenu Vám věci, na které byste možná zapomněli, a
                            upozorním
                            Vás na úskalí, která by mohla tento Váš velký den provázet.
                            <br/>
                            Poradím Vám, jak sestavovat harmonogram, rozpočet a zasedací pořádek.
                            <br/>
                            Jak si vybrat vhodné místo pro obřad a hostinu.
                            <br/>
                            Jak si vybrat dodavatele.<br/>
                            Matrika a jaké doklady je třeba doložit.<br/>
                            Rady, návody, konzultace. <br/><br/></small>
                        <h2>od 2.490 Kč <br/><br/></h2>
                    </div>
                </div>
                <hr/>
                <div className="row" style={{maxWidth: "80%", textAlign: "center", margin: "0 auto"}}>
                    <div className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <h2>2. KOORDINACE OBŘADU<br/><br/></h2>
                        <small style={{fontSize: "1rem"}}>Dohlédnu na to, aby celý obřad proběhl hladce a podle Vašich
                            představ.<br/>
                            Úvodní schůzka, na které spolu probereme Vaše představy a nápady.<br/>
                            Emailová a telefonická komunikace.<br/>
                            Instrukce ohledně nutné administrativy.<br/>
                            Sestavení časového harmonogramu.<br/>
                            Kontrola a příprava místa obřadu.<br/>
                            Uvítání, myrtování a usazení hostů.<br/>
                            Koordinace oddávajícího, hudby, fotografa a dalších.<br/>
                            Pomoc při seřazení svatební kolony.<br/><br/></small>
                        <h2>od 3.990 Kč<br/><br/></h2>
                    </div>
                </div>
                <hr/>
                <div className="row" style={{maxWidth: "80%", textAlign: "center", margin: "0 auto"}}>
                    <div className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <h2>3. KOORDINACE SVATEBNÍHO DNE<br/><br/></h2>
                        <small style={{fontSize: "1rem"}}>Zajistím hladký průběh obřadu, přesun svatebčanů a dohled na
                            svatební hostinu.<br/>
                            Úvodní schůzka a schůzka pár dní před svatbou, na které doladíme poslední detaily.<br/>
                            Emailová a telefonická komunikace.<br/>
                            Až 8 hodin koordinace ve svatební den.<br/>
                            Instrukce ohledně nutné administrativy.<br/>
                            Sestavení časového harmonogramu a zasedacího pořádku.<br/>
                            Koordinace obřadu a všech dodavatelů.<br/>
                            Dohled nad přesunem hostů na místo hostiny.<br/>
                            Usazení hostů.<br/>
                            Koordinace hostiny.
                            <br/><br/></small>
                        <h2>od 9.990 Kč <br/><br/></h2>
                    </div>
                </div>
                <hr/>
                <div className="row" style={{maxWidth: "80%", textAlign: "center", margin: "0 auto"}}>
                    <div className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                        <h2>4. SVATBA NA KLÍČ<br/><br/></h2>
                        <small style={{fontSize: "1rem"}}>Pomohu Vám naplánovat a zorganizovat celou Vaší svatbu nebo
                            pouze některé její části
                            podle Vašich
                            požadavků.<br/>
                            Obsah všech služeb z balíčku Koordinace svatebního dne.<br/>
                            Až 3 osobní schůzky.<br/>
                            Společný výběr vhodných lokalit a rezervace termínu.<br/>
                            Vytvoření časového harmonogramu a rozpočtu a následná práce s nimi.<br/>
                            Vyhledání a komunikace s dodavateli.<br/>
                            Zajištění dalších služeb dle Vašich přání.
                            <br/><br/></small>
                        <h2>od 14.990 Kč <br/><br/></h2>
                    </div>
                </div>
                <div className="container" style={{marginTop: "0vw"}}>
                    <div className="row">
                        <div className="col-12 text-center">
                            <img alt="Responsive" className="img-fluid"
                                 src={ornament2Image}
                                 style={{height: "20vh", paddingLeft: "1vw"}}/>
                        </div>
                    </div>
                    <hr/>
                    <div className="container" style={{marginTop: "0vw"}}>
                        <div className="row" style={{maxWidth: "80%", textAlign: "center", margin: "0 auto"}}>
                            <div className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
                                <h2>KONTAKT<br/><br/></h2>
                                <small style={{fontSize: "1rem"}}>
                                    Pavla Částková<br/>
                                    Tel.: +420 602 117 411 <br/>
                                    E-mail: <a href="mailto:castkova.pavla@email.cz" target="_blank" rel="noreferrer"><i
                                    className="far fa-envelope"></i></a>
                                    <p></p>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Section;