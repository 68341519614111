import './App.css';
import Nav from "./components/Nav";
import Carousel from "./components/Carousel";
import Section from "./components/Section";
import Footer from './components/Footer';


function App() {
    return (
        <div className="App">
            {/* <HeadNew/> */}
            <Nav/>
            <Carousel/>
            <Section/>
            <Footer/>

        </div>
    );
}

export default App;
