import React from 'react';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row" style={{margin: "30px", fontSize: "0.8rem"}}>
                    <p style={{position: "relative", bottom: "10px", margin: "0 auto"}}>
                        © 2024 Svatby na provázku |
                        <a href="https://reeddesign.cz" target="_blank" rel="noreferrer"> ★ REED Design Inc.</a>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;