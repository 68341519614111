import React from 'react';
import Profil_na_fb from '../images/svatby-na-provazku---profil---na-fb---112016-crop-u110.jpg';


const Nav = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light margin-auto flex-column background_nav">
            <a className="navbar-brand mx-auto" href="https://svatbynaprovazku.cz">
                <img alt="Responsive image" className="img-fluid"
                     src={Profil_na_fb}
                     style={{height: "24vh", paddingLeft: "1vw"}}/>
            </a>
        </nav>
    );
}

export default Nav;